*{
    font-family: Nunito, sans-serif;
}
.topWindow {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    /* Чтобы приклеенный элемент располагался над всеми остальными элементами страницы */
}

.question {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin-right: 1vh;
    margin-bottom: 1vh;
    border: 1px solid #44444c;
}

.askme {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin-right: 1vh;
    margin-bottom: 1vh;
}

.askme p {
    display: none;
    padding: 10px;
    border-radius: 13px;
    border: 1px solid #44444c;
    background: #FFFFFF;
    color: #44444c;
}

.askme img {
    width: 60px;
    height: 60px;
    float: right;
    -webkit-animation: pulsing 2s infinite;
    animation: pulsing 2s infinite;
}
.askme img:hover{
    animation: none;
}
@-webkit-keyframes pulsing {
    0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5)
    }
    50% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }
    100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }
}

@keyframes pulsing {
    0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5)
    }
    50% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }
    100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }
}