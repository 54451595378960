.card-horizontal {
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    margin: 2em;
   background-color: transparent;

}
.card-horizontal .card-body-fluid{
    align-content: center;
}
.card-img-left, .card-img-right{
    border-radius: 1em;
   background-color: white;
}
.card-link{
    float: right;
    float: inline-end;
}
.card-link:hover{
    text-decoration: underline;
}
.breadcrumb{
    background: transparent;
}
.breadcrumb-item, .breadcrumb-item > a{
    color: #44444c;
}