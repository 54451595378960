*{
    margin: 0;
    padding: 0;
}

.list{
    list-style: decimal;
}
.scientific-page{
    background-color: #faf7dc;
    color: #2b2b2f;
    padding: 3em;
    padding-bottom: 80%;
}
