* {
    margin: 0;
    padding: 0;
}

.photo-page {
    color: #44444c;
    padding: 3em;
    padding-bottom: 80%;
}

