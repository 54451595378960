
/*анимация гамбургера*/
.navbar-toggler.active  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='grey' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8l20 16M4 24L24 8'/%3E%3C/svg%3E");
}

.nav-link{
    font-weight: 700;
    font-size: larger;
}
.nav{
    background-color: #ffcc00;
    padding: 0.5em;
    margin-bottom: 4vw;
}
.nav-item:hover{
    
    border-radius: 6px;
}
.nav-link:hover{
    color: black;
}
.dropdown:hover > .dropdown-menu {
    display: block; 
    margin: 0;
    width: fit-content;
    border-color: #ffcc00;
   } 
.dropdown-menu{
    background-color: #faf7dc;   
    padding: 0.5em;
}
.dropdown:hover > .nav-link {
   color: black; 
}
.dropdown-item{
    padding-left: 3em;
}
.dropdown-item:hover{
    background-color: #ffcc00;
}