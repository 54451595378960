*{
    margin: 0;
    padding: 0;
}
.contact-page{
    color: #2b2b2f; 
    padding: 3em;
}
h2{
    color: #2b2b2f;
    text-shadow: 1px 1px 1px #44444c;
}

.adres{
    color: #2b2b2f;
}
.contact-jumbotron{
    background-color: #faf7dc;
    border: 3px outset #ffcc00;
    color: #2b2b2f;
}
.tel{
    padding-left: 2em;
}
.tel:hover{
    color: #ffcc00;
    text-shadow: 1px 1px 1px #44444c;
}
.inst{
    width: 7vh;
}
.inst:hover{
    transform: rotate(37deg);
}
@media screen and (max-device-width: 1025px) { 
  li {
      font-size: 4vmin;
      line-height: 4vh;
  }
  .tel{
    font-size: 4vmin;
    line-height: 4vh;
    padding: 0;
  }
  .contact-page{
      padding: 1rem;
  }

}