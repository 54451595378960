*{
    margin: 0;
    padding: 0;
}
@media screen and (max-device-width: 1025px) { 

    .certificates{
      font-size: 4vmin;
      padding: 0;
    }
  
  }
.biography-page{
    color: #2b2b2f;
    padding: 3em;
    font-size: 1.2rem;
}

.certificates{
    color: #2b2b2f;
}
.certificates:hover{
    color: #2b2b2f;
}
