*{
    margin: 0;
    padding: 0;
}
.biography-page{
    color: #2b2b2f;
    padding: 3em;
}
p{
    line-height: 1.6rem;
}