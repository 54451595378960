* {
    margin: 0;
    padding: 0;
}

.photo-page {

    color: #2b2b2f;
    padding: 3em;
    padding-bottom: 50%;
}
.PhotoGrid__imageWrapper___1YTK9 {
    border: 3px solid #ffcc00;
}
