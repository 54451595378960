.myBottom {
    background-color: #ffcc00;
    padding: 2em 0;
    color: #2b2b2f;
}

.myBottom .tel:hover {
    color: #faf7dc;
    text-shadow: 1px 1px 1px #44444c;
}

@media screen and (max-device-width: 1025px) {
    li, h2, label, .form-control {
        font-size: 4vmin;
    }
    .tel {
        font-size: 4vmin;
        padding: 0;
    }
    .review {
        font-size: 4vmin;
    }
}