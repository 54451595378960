@media screen and (max-device-width: 1025px) { 
    .card-text {
        font-size: 3vmin;
        line-height: 1em;
        text-align: left;
    }
    .photoLine{
        padding-bottom: 10vmin;
    }
    .card-img-overlay{
        width: 70%;
    }
    .container, .container-sm {
        max-width: fit-content;
    }
}

.card{
    background: none;
    border: none;
    color: #2b2b2f;
    text-align: center;
    padding: 20px 0 0 0;
}
.card-img-overlay{
        width: 60%;
        align-content: center;
    }


.photoLine{
    background-color: #faf7dc;
}
