@import url('https://fonts.googleapis.com/css?family=Josefin+Sans|Open+Sans&display=swap');
.rounded-circle {
    background-color: #faf7dc;
    width: 300px;
    height: 300px;
    color: #2b2b2f;
    border: 1px solid #ffcc00;
    box-shadow: 2px 2px 3px #2b2b2f;
}

.interesting {
    color: #2b2b2f;
    background-color: #faf7dc;
    padding: 3em 0;
    
}

h2 {
    color: #2b2b2f !important;
    font-family: 'Josefin Sans', sans-serif;
    
}

h2::after {
    content: "";
    width: 30% !important;
    position: absolute;
    margin: 0 auto;
    height: 2px !important;
    background: #ffcc00 !important;
    left: 0;
    right: 0;
    bottom: -10px;
}

@media screen and (max-device-width: 1025px) {
    .rounded-circle {
        width: 150px;
        height: 150px;
    }
}