.container-fluid {
    padding: 10px 20px;
    background-color: #FFCC00;
}

.font-weight-bold {
    color: #2b2b2f;
}

h1 {
    color: #2b2b2f;
}

.btn {
    padding: 10px 20px;
}
.btn-yellow-outline{
    background-color: #FFCC00;
    border-color: #2b2b2f;
}
.btn-yellow-outline:hover{
    background-color: #faf7dc;
}
.btn-yellow{
    background-color: #faf7dc;
    border-color: #faf7dc;
}
.btn-yellow:hover{
    font-size: 1.2vw;
}

.btn:hover a {
    color: #44444c;
}

a {
    color: #2b2b2f;
}

@media screen and (max-device-width: 1025px) {
    h1, p, h3, a {
        font-size: 4vmin;
    }
    .row {
        margin-right: 15px;
        margin-left: -20px;
    }
    .btn{
        padding: 10px;
    }
}