.commView{

    color: #2b2b2f;
    padding: 3em;
}
.commViewcont{
background-color: #faf7dc;
padding: 2vw 7vw;
border-radius: 2vw;
}
h2{
	position: relative;
}
h2::after{
	content: "";
	width: 130px;
	position: absolute;
	margin: 0 auto;
	height: 3px;
	background: #2b2b2f;
	left: 0;
	right: 0;
	bottom: -10px;
}
.carousel-inner .carousel-item .img-box {
	width: 135px;
	height: 135px;
}
.d-block{
    width: 130px;
	height: 130px;
}
.carousel-control-prev{
	left: -100px;

}
.carousel-control-next{
	right: -100px;
	
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232b2b2f' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232b2b2f' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;}
@media screen and (max-device-width: 1025px) { 
    strong,h6,p{
        font-size: 4vmin;
	}
	.carousel-control-prev{
		left: -40px;
	}
	.carousel-control-next{
		right: -40px;
	}
	.commView{
		padding: 1em;
	}
  }
